<template>
    <div class="approval-detail" v-if="certsData">
        <div class="page-header">
            <el-row>
                <el-col :span="24">
                    <div class="header-desc">
                        <router-link to='/ecert/list'><el-button icon="el-icon-arrow-left" round>{{ $t('data.public.back') }}</el-button></router-link>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20" class="mb-20">
            <el-col :span="16">
                <el-card shadow="always">
                    <div class="approval-info">
                        <!-- <p class="mb-20">
                            {{ $t('data.eCertDetail.topic') }}: {{certsData.topic}}<br>
                            {{ $t('data.eCertDetail.group') }}: {{certsData.group.name}}<br>
                            {{ $t('data.eCertDetail.createTime') }}: {{certsData.created_at}}<br>
                            {{ $t('data.eCertDetail.pendingApplication') }}: {{certsData.pending_application}}<br>
                            {{ $t('data.eCertDetail.approvedApplication') }}: {{certsData.approved_application}}<br>
                            {{ $t('data.eCertDetail.eCertIssueDate') }}: {{certsData.issue_date}}
                        </p> -->
                        <div class="mb-20 application-text text-flex">
                            <div class="name-left">
                                {{ $t('data.eCertDetail.topic') }}:<br>
                                {{ $t('data.eCertDetail.group') }}:<br>
                                {{ $t('data.eCertDetail.createTime') }}:<br>
                                {{ $t('data.eCertDetail.pendingApplication') }}:<br>
                                {{ $t('data.eCertDetail.approvedApplication') }}:<br>
                                {{ $t('data.eCertDetail.eCertIssueDate') }}:
                            </div>
                            <div class="val-right">
                                {{certsData.topic}}<br>
                                {{certsData.group.name}}<br>
                                {{certsData.created_at}}<br>
                                {{certsData.pending_application}}<br>
                                {{certsData.approved_application}}<br>
                                {{certsData.issue_date}}
                            </div>
                        </div>
                        <div class="approval-ststus">
                            <el-select v-model="certsData.status" :placeholder="$t('data.status.status')" size="small" style="margin-right: 15px">
                                <el-option :label="$t('data.status.valid')" :value="1"></el-option>
                                <el-option :label="$t('data.status.expired')" :value="2"></el-option>
                                <el-option :label="$t('data.status.cancel')" :value="3"></el-option>
                                <el-option :label="$t('data.status.change')" :value="4"></el-option>
                                <el-option :label="$t('data.status.amended')" :value="5"></el-option>
                                <el-option :label="$t('data.status.remark')" :value="6"></el-option>
                            </el-select>

                            <el-popover
                                placement="top"
                                :width="160"
                                v-model:visible="visible">
                                <p style="margin-bottom: 10px">{{ $t('data.status.isStatusDesc') }}？</p>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="visible = false">{{ $t('data.public.cancel') }}</el-button>
                                    <el-button type="primary" size="mini" @click="ststusUpdate()">{{ $t('data.public.confirm') }}</el-button>
                                </div>
                                <template #reference>
                                    <el-button type="primary" size="small">{{ $t('data.public.update') }}</el-button>
                                </template>
                            </el-popover>

                            <p style="font-size: 12px;">{{ $t('data.public.selectExpiredInfo') }}</p>

                        </div>

                        <img :src="certsData.picture" alt="">
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">

                <el-card shadow="always" class="mb-20" align="center">
                    <h3 class="mb-20">{{ $t('data.eCertDetail.totalNumberIssues') }}：{{certsData.total_issues}}</h3>
                    <router-link :to="'/ecert/edit/'+templateId"><el-button type="primary" round>{{ $t('data.public.publishAgain') }}</el-button></router-link>
                </el-card>

                <el-card shadow="always" class="mb-20">
                    <div class="approval-scan">
                        <p>{{ $t('data.eCertDetail.scanQRCode') }}</p>
                        <p>{{ $t('data.eCertDetail.eCertTitle') }}：{{certsData.cert_name}}</p>
                        <img :src="certsData.qrcode" alt="">
                        
                    </div>
                </el-card>

                <el-card shadow="always" align="center">
                    <div class="approval-scan-btn">
                            <el-button type="primary" size="small" @click="downloadCert">{{ $t('data.public.downloadPdf') }}</el-button>
                            <router-link :to="'/ecert/qrcode-print?template_id='+templateId" style="margin-left: 10px;">
                                <el-button type="primary" size="small">{{ $t('data.public.printQRcode') }}</el-button>
                            </router-link>
                            <!-- <el-button type="primary" size="small" @click="downloadCert" style="margin-left: 10px;">{{ $t('data.public.printeCert') }}</el-button> -->
                            <router-link :to="'/ecert/print?template_id='+templateId" style="margin-left: 10px;">
                                <el-button type="primary" size="small">{{ $t('data.public.printeCert') }}</el-button>
                            </router-link>
                        </div>
                </el-card>
                
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="24">
                <el-card shadow="always">
                    <div class="mb-10" align="right">
                        <el-button type="primary" @click="excelExport">{{ $t('data.public.exportExcel') }}</el-button>
                    </div>
                    <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                        :label="$t('data.eCertDetail.table.photo')"
                        width="80">
                            <template #default="scope">
                            <img :src="scope.row.member.avatar" alt="" class="table-user-photo">
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="member.name"
                        :label="$t('data.eCertDetail.table.applicantName')"
                        width="220">
                        </el-table-column>
                        <el-table-column
                        prop="number"
                        width="280"
                        :label="$t('data.eCertDetail.table.eCertNumber')">
                            <template #default="scope">
                                <!-- <router-link :to="'/ecert/application-detail/'+scope.row.id">
                                    {{scope.row.number}}
                                </router-link> -->
                                <el-button type="text" @click="openNextPage(scope.row)">{{scope.row.number}}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="issue_date"
                        :label="$t('data.eCertDetail.table.issueDate')">
                        </el-table-column>
                        <el-table-column
                        prop="branch.name"
                        :label="$t('data.eCertDetail.table.campusBranch')">
                        </el-table-column>
                        <el-table-column
                        :label="$t('data.status.status')">
                            <template #default="scope">
                                <p v-if="scope.row.final_status === 1">{{ $t('data.finalStatus.requested') }}</p>
                                <p v-if="scope.row.final_status === 2">{{ $t('data.finalStatus.deleted') }}</p>
                                <p v-if="scope.row.final_status === 3">{{ $t('data.finalStatus.approved') }}</p>
                                <p v-if="scope.row.final_status === 4">{{ $t('data.finalStatus.accepted') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        :label="$t('data.eCertDetail.table.approval')">
                            <template #default="scope">
                                <p v-if="scope.row.final_status === 1">{{ $t('data.finalStatus.requested') }}</p>
                                <p v-if="scope.row.final_status === 2">{{ $t('data.finalStatus.deleted') }}</p>
                                <p v-if="scope.row.final_status === 3">{{ $t('data.finalStatus.approved') }}</p>
                                <p v-if="scope.row.final_status === 4">{{ $t('data.finalStatus.accepted') }}</p>
                            </template>
                        </el-table-column>
                            
                        <el-table-column
                        prop="approved_at"
                        :label="$t('data.eCertDetail.table.approvedTime')">
                        </el-table-column>
                        <el-table-column
                        prop="issuer.name"
                        :label="$t('data.eCertDetail.table.approvedAccount')">
                        </el-table-column>
                    </el-table>
                    <table-pagination :page="page" @updatePage="updatePage"></table-pagination>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { certsList,certsTemplateDetails,certsStatus,certsDownload,membersExport } from '../../api/index'
import tablePagination from "@/components/tablePagination/TablePagination"
export default {
    name: 'Approval Detail',
    components:{
        tablePagination
    },
    data(){
        return {
          certsData: null,
          tableData: null,
          visible: false,
          page: {},
          templateId: this.$route.params.id
        }
    },
    mounted(){
        this.initPageData();
    },
    methods: {
        initPageData(data){
            let apiData = {
              id: this.$route.params.id
            }
            certsTemplateDetails({apiData}).then( res => {
                this.certsData = res.data;
            })
            apiData.template_cert_id = this.$route.params.id;
            if(data){
                Object.assign(apiData,data)
            }
            certsList({apiData}).then( res => {
                this.tableData = res.data;
                this.page = res.meta.page;
            })
        },
        ststusUpdate(){
            let apiData = {
              id: this.$route.params.id,
              status: this.certsData.status
            }
            certsStatus({apiData}).then( res => {
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
                this.visible = false;
            })
            
        },
        downloadCert(){
            let apiData = {
              id: this.$route.params.id
            }
            certsDownload({apiData}).then( res => {
                window.open(res.data.url);
            })
        },
        excelExport(){
            let apiData = {
                template_cert_id: this.$route.params.id
            }
            membersExport({apiData}).then( res => {
                window.open(res.data.url);
            })
        },
        openNextPage(row){
            if(row.final_status === 1){
                window.location.href = '/ecert/approval'
            } else {
                window.location.href = '/ecert/application-detail/'+row.id
            }
        },
        updatePage(data){
            this.initPageData(data)
        }
    }
}
</script>

<style scoped>
.approval-info{
    text-align: center;
}
.approval-info p{
    text-align: left;
    line-height: 32px;
}
.approval-ststus{
    display: block;
    width: 100%;
    margin-bottom: 30px;
    text-align: left;
}
.approval-info img{
    width: 400px;
    height: auto;
    margin: 0 auto;
    text-align: center;
}
.approval-scan{
    text-align: center;
}
.approval-scan img{
    max-width: 200px;
    height: auto;
}
.approval-scan p{
    margin-bottom: 15px;
}
.copy-link-btn{
    display: block;
    margin: 15px 0px;
}
.approval-info > .application-text > div{
    line-height: 30px;
}
</style>